import i18n from 'i18next';
import { reactI18nextModule } from "react-i18next";
//import Backend from 'i18next-node-fs-backend';
//import XHR from 'i18next-xhr-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

//var Backend = require('i18next-node-fs-backend');

import localesEN from './locales/en/common.json';
import localesNL from './locales/nl/common.json';
import localesFR from './locales/fr/common.json';
import localesDE from './locales/de/common.json';
import localesSV from './locales/sv/common.json';
import localesDK from './locales/dk/common.json';
import localesFI from './locales/fi/common.json';

i18n
  .use(reactI18nextModule)
  //.use(Backend)
  //.use(LanguageDetector)
  .init({
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    debug: false,

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    backend: {
      // path where resources get loaded from
      loadPath: '/locales/{{lng}}/{{ns}}.json',

      // path to post missing resources
      addPath: '/locales/{{lng}}/{{ns}}.missing.json',

      // jsonIndent to use when storing json files
      jsonIndent: 2
    }
  });

i18n.addResources('en', 'common', localesEN);
i18n.addResources('nl', 'common', localesNL);
i18n.addResources('fr', 'common', localesFR);
i18n.addResources('de', 'common', localesDE);
i18n.addResources('sv', 'common', localesSV);
i18n.addResources('dk', 'common', localesDK);
i18n.addResources('fi', 'common', localesFI);

export default i18n;
